.app {
  /*height: 100vh;*/
  display: flex;
  align-items: center;
}
.dropzone {
  flex: 1;
  border: 1px dashed #999;
}
.footer-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
ol.progtrckr {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 12px;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\25cf";
  color: silver;
  background-color: transparent;
  width: 1.2em;
  line-height: 2.5em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 2.5em;
  line-height: 2.5em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 2.5em;
  line-height: 2.5em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}